import { usePopup } from '@src/contexts/PopupContext';
import { useIsDesktop } from '@src/hooks/util';
import MainLayout from '@src/layouts/0_main/MainLayout';
import CommmonLayout from '@src/layouts/common/CommonLayout';
import FileService from '@src/services/file/FileService';
import { useTheme } from '@src/types/theme';
import { useEffect } from 'react';
import Image from "next/image";

const meta = {
	title: 'soritown',
	siteDescription: 'soritown',
	pageUrl: 'https://www.soritown.com',
};

export type HomeProps = {
    popUpImage?: string;
};

export default function Home(props: HomeProps)
{
    const popup = usePopup();
    const theme = useTheme();
    const isDesktop: boolean = useIsDesktop();

    useEffect(() => {
        if (props.popUpImage)
        {
            popup.openPopup(
                <Image
                    style={{ objectFit: "cover", borderRadius: "1.1vw" }}
                    src={ props.popUpImage }
                    fill
                    priority
                    alt=""
                />
            );
        }
    }, []);
    
    return (
        <CommmonLayout { ...meta } backgroundColor={ theme.themeColor.backgroundSky1 } footerColor={ isDesktop ? "" : "#62CFFF" } coloredMenu={ false }>
            <MainLayout />
        </CommmonLayout>
    );
}

export async function getServerSideProps(context: any)
{
    try
    {
        let results = await FileService.getPopup();

        return {
            props: {
                popUpImage: results
            }
        }
    }
    catch (error)
    {
        console.error("Error fetching popup image:", error);

        return {
            props: {
                popUpImage: null
            }
        }
    }
}