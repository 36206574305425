import { soritownTheme } from '@src/theme/default';
import { breakPointType } from '@src/theme/media';
import { ReactNode, createContext, useContext } from 'react';

export interface ThemeType {
	breakPoint: breakPointType;
	themeColor: {
        backgroundSky1: string,
        backgroundSky2: string,
        backgroundSky3: string,
        backgroundSky4: string,
        backgroundRed1: string,
        backgroundRed2: string,
        backgroundRed3: string,
        Textyellow: string, 
        text: string,
    }
};

const ThemeContext = createContext<ThemeType | undefined>(soritownTheme);

interface ThemeProviderProps {
    children: ReactNode;
    theme: ThemeType;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children, theme  }) => {
    return (
        <ThemeContext.Provider value={ theme }>
            { children }
        </ThemeContext.Provider>
    );
};

export const useTheme = (): ThemeType => {
    const context = useContext(ThemeContext);
    
    if (!context)
      throw new Error("useTheme must be used within a ThemeProvider");
    
    return context;
};