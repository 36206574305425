import cssMixin from "@styles/cssMixin";
import styled from "styled-components";
import Image from "next/image"
import { RefObject, useEffect, useRef, useState } from "react";
import { useIsDesktop, useIsMobile, useScrollY, useWindowHeight, useWindowWidth } from "@src/hooks/util";
import Link from "next/link";
import { ThemeType } from "@src/types/theme";
import { useSelector } from "react-redux";
import { RootState } from "@src/store";

export default function MainLayout()
{
    const wrapperRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
    const imageRafArray: RefObject<HTMLDivElement>[] = Array.from({ length: 5 }, () => useRef<HTMLDivElement>(null))
    const windowWidth = useWindowWidth();
    const windowHeight = useWindowHeight();
    const isDesktop = useIsDesktop();

    const [ height, setHeight ] = useState(0);

    useEffect(() => {
        let start: DOMHighResTimeStamp = -1;

        let animate = (timestamp: DOMHighResTimeStamp) => {
            if (start === -1)
                start = timestamp;
            
            let elapsed = timestamp - start;
        
            // cos 함수를 이용한 수직 움직임
            let radius: number = 30 * (windowWidth > 1440 ? 1440 : windowWidth) / 1440;// 반경
            let y = Math.cos(elapsed * 0.002) * radius;
        
            if (imageRafArray[0].current)
            {
                imageRafArray[0].current.style.transform = `translateY(${y}px)`;
            }
        
            // 다음 애니메이션 프레임을 요청
            requestAnimationFrame(animate);
        };
        
        let anim = requestAnimationFrame(animate);

        return () => {
            if (imageRafArray[0].current)
                cancelAnimationFrame(anim);
        };
    }, [isDesktop]);

    return (
        <Wrapper>
            <ImgGroup ref={ wrapperRef }>
                <Link href="/1/soritown">
                    <InstrumentWrap ref={ imageRafArray[0] }>
                        <Image
                            src="/source/0_Main_Page/3730143-17.png"
                            fill={ true }
                            alt=""
                        />
                    </InstrumentWrap>
                </Link>
                <CloudWrap1 ref={ imageRafArray[1] }>
                    <Image
                        src="/source/0_Main_Page/3730143-18.png"
                        fill={ true }
                        alt=""
                    />
                </CloudWrap1>
                <CloudWrap2 ref={ imageRafArray[2] }>
                    <Image
                        src="/source/0_Main_Page/3730143-19.png"
                        fill={ true }
                        alt=""
                    />
                </CloudWrap2>
                <CloudWrap3 ref={ imageRafArray[3] }>
                    <Image
                        src="/source/0_Main_Page/3730143-20.png"
                        fill={ true }
                        alt=""
                    />
                </CloudWrap3>
                <CloudWrap4 ref={ imageRafArray[4] }>
                    <Image
                        src="/source/0_Main_Page/3730143-21.png"
                        fill={ true }
                        alt=""
                    />
                </CloudWrap4>
            </ImgGroup>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    ${ cssMixin.container };
    height: calc(100vh - 214px);
    overflow-x: hidden;

    ${({ theme }: { theme: ThemeType }) => theme.breakPoint.mobile`
        height: 100vh;
	`};
`

const ImgGroup = styled.div`
    width: 84vw;
    height: 34.2vw;
    max-width: 1210px;
    max-height: 493px;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    user-select: none;
`;

const InstrumentWrap = styled.div`
    width: 63vw;
    height: 34vw;
    max-width: 905px;
    max-height: 493px; 
    position: absolute;
    top: 0px;
    left: 8%;
    z-index: 1;
`;

const CloudWrap1 = styled.div`
    width: 28vw;
    height: 11vw;
    max-width: 403px;
    max-height: 161px; 
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 2;
`;

const CloudWrap2 = styled.div`
    width: 29.5vw;
    height: 7.8vw;
    max-width: 425px;
    max-height: 122px; 
    position: absolute;
    top: 25.5%;
    right: 0;
    z-index: 0;
`;

const CloudWrap3 = styled.div`
    width: 57vw;
    height: 7vw;
    max-width: 823px;
    max-height: 100px; 
    position: absolute;
    top: 25.5%;
    left: 0;
    z-index: 0;
`;

const CloudWrap4 = styled.div`
    width: 25vw;
    height: 12.5vw;
    max-width: 357px;
    max-height: 180px; 
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
`;